<template>
  <div class="orders">
    <h1 class="text-sm-h3 text-h4 my-3 text-center">Orders</h1>
    <div v-if="loading">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </div>
    <v-row>
      <OrderCard
        v-for="order in orders"
        :key="order._id"
        :order="order"
      ></OrderCard>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import OrderCard from "@/components/OrderCard.vue";
export default {
  data() {
    return {
      loading: false,
      orders: [],
    };
  },
  components: {
    OrderCard,
  },
  created() {
    this.loading = true;
    axios
      .get("https://zsh-order-api.herokuapp.com/api/orders")
      .then((response) => {
        this.orders = response.data;
        this.loading = false;
      });
  },
};
</script>

<style></style>
