<template>
  <v-col cols="12" lg="3" md="4" sm="6">
    <v-card
      :to="{ name: 'Order', params: { id: order._id } }"
      :color="order.status.stReleased ? 'green' : 'red'"
      class="lighten-5"
      flat
    >
      <v-card-title class="text-h5">
        {{ order.orderNumber }} - {{ order.customer }}
      </v-card-title>
      <v-card-text class="items">
        <ul>
          <li v-for="item in order.items" :key="item._id">
            {{ item.model }} x {{ item.quantity }}
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "OrderCard",
  components: {},
  props: {
    order: Object,
  },
};
</script>

<style scoped>
.items {
  height: 80px;
  overflow-y: scroll;
}
ul {
  padding: 0;
}
li {
  font-size: 1rem;
  list-style-type: none;
}
</style>
